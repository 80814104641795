import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Drawer, Typography, Box, CssBaseline, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon,  Toolbar } from '@mui/material';
import logo from '../../images/logo2.png'
import { CardMedia } from '@mui/material';
import { Icon } from "@iconify/react";
import icon1 from '../../images/icon1.png'
import icon2 from '../../images/icon2.png'
import icon3 from '../../images/icon3.png'
import icon4 from '../../images/icon4.png'
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router';
import { ConnectEthWallet } from './ConnectEthWallet';
import { useGeneralDataContext } from '../../hooks/useGeneralDataContext';
import { ConnectBtcWallet } from './ConnectBtcWallet';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const { children } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const url = window.location.href.split('/')[3]
    const navigate = useNavigate()
    const {ethAccount, btcAccount} = useGeneralDataContext()
    console.log(ethAccount)

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const drawer = (
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'space-between', height:'97%'}}>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4rem' }}>
                    <CardMedia component='img' image={logo} sx={{ height: '1.7rem', width: 'auto' }} />
                </Box>
                <Divider sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.08)' }} />
                <List sx={{ display: 'flex', flexDirection: 'column', padding: '0 5%', alignItems: 'center', margin: '1.3rem 0'}} >
                    <ListItem onClick={() => navigate('/dashboard')} sx={url === 'dashboard' ? { borderRadius: '12px', background: 'rgba(255, 255, 255, 0.08)', padding: '2%', color: 'white' } : { borderRadius: '12px', background: 'transparent', padding: '2%', color: 'rgba(255, 255, 255, 0.6)' }}>
                        <ListItemButton>
                            <ListItemIcon sx={{ margin: '0', minWidth: '2rem' }}>
                                <Icon icon="mage:dashboard-fill" style={url === 'dashboard' ? { fontSize: '1.3rem', color: 'white' } : { fontSize: '1.3rem', color: 'rgba(255, 255, 255, 0.6)' }} />
                            </ListItemIcon>
                            <Typography variantMapping='p' sx={{ fontFamily: 'Inter !important', fontWeight: '300' }} >Dashboard</Typography>
                        </ListItemButton>
                    </ListItem>

                    <ListItem onClick={() => navigate('/assetbridge')} sx={url === 'assetbridge' ? { borderRadius: '12px', background: 'rgba(255, 255, 255, 0.08)', padding: '2%', color: 'white', marginTop: '1rem' } : { borderRadius: '12px', marginTop: '1rem', background: 'transparent', padding: '2%', color: 'rgba(255, 255, 255, 0.6)' }}>
                        <ListItemButton>
                            <ListItemIcon sx={{ margin: '0', minWidth: '2rem' }}>
                                <Icon icon="hugeicons:paragraph-spacing" style={url === 'assetbridge' ? { fontSize: '1.3rem', color: 'white' } : { fontSize: '1.3rem', color: 'rgba(255, 255, 255, 0.6)' }} />
                            </ListItemIcon>
                            <Typography variantMapping='p' sx={{ fontFamily: 'Inter !important', fontWeight: '300' }} >Asset Bridge</Typography>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.08)' }} />
                <List sx={{ display: 'flex', flexDirection: 'column', padding: '0 5%', alignItems: 'center', marginTop: '5%' }} >
                    <ListItem sx={url === 'stake' ? { borderRadius: '12px', background: 'rgba(255, 255, 255, 0.08)', padding: '2%', color: 'white' } : { borderRadius: '12px', background: 'transparent', padding: '2%', color: 'rgba(255, 255, 255, 0.6)' }}>
                        <ListItemButton>
                            <ListItemIcon sx={{ margin: '0', minWidth: '2rem' }}>
                                <Icon icon="solar:pulse-linear" style={url === 'stake' ? { fontSize: '1.3rem', color: 'white' } : { fontSize: '1.3rem', color: 'rgba(255, 255, 255, 0.6)' }} />
                            </ListItemIcon>
                            <Typography variantMapping='p' sx={{ fontFamily: 'Inter !important', fontWeight: '300' }} >Stake</Typography>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Box sx={{display:'flex', justifyContent:'space-between',padding: '0 10%'}}>
                <Icon icon="pajamas:twitter" style={{ fontSize: '16px', color: 'white' }} />
                <CardMedia component='img' image={icon1} sx={{ width: 'auto', height: '18px' }} />
                <CardMedia component='img' image={icon2} sx={{ width: 'auto', height: '18px' }} />
                <CardMedia component='img' image={icon3} sx={{ width: 'auto', height: '18px' }} />
                <CardMedia component='img' image={icon4} sx={{ width: 'auto', height: '18px' }} />
            </Box>
        </Box>
    );

    // Remove this const when copying and pasting into your project.

    return (
        <Box sx={{ display: 'flex' }}>
            <Box component='div' sx={{ background: 'linear-gradient(90.01deg, #F5872A 0.01%, #E84C63 99.99%)', overFlowX: 'hidden !important', position: 'absolute', top: '-20rem', left: '-20%', width: { lg: '663px', md: '553px', sm: '553px', xs: '500px' }, height: { lg: '663px', md: '553px', sm: '553px', xs: '500px' }, borderRadius: '783px', filter: 'blur(432px)', zIndex: -1 }}></Box>
            <Box component='div' sx={{ background: 'linear-gradient(90.01deg, #F5872A 0.01%, #E84C63 99.99%)', overFlowX: 'hidden !important', position: 'absolute', bottom: '-20rem', right: '-20%', width: { lg: '553px', md: '433px', sm: '553px', xs: '500px' }, height: { lg: '553px', md: '433px', sm: '553px', xs: '500px' }, borderRadius: '683px', filter: 'blur(332px)', zIndex: -1 }}></Box>

            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    boxShadow: 'none',
                    bgcolor: 'transparent',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.08)'
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{display:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%'}}>
                    <ConnectEthWallet/>
                    <ConnectBtcWallet/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: drawerWidth, borderRight: '1px solid rgba(145, 147, 153, 0.12)',
                            background: 'rgba(91, 95, 105, 0.10) !important', backdropFilter: 'blur(7px)'
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: drawerWidth, borderRight: '1px solid rgba(145, 147, 153, 0.12)',
                            background: 'rgba(91, 95, 105, 0.10) !important', backdropFilter: 'blur(7px)'
                        }
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {ethAccount && btcAccount ?  children : <Typography variantMapping='p' sx={{ fontSize: '20px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Connect your wallets to continue</Typography> }

                

            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
