import React from 'react';
import { Box, Typography, Modal, CardMedia, Button } from '@mui/material';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import logo2 from '../../images/logo2.png'
import { Icon } from "@iconify/react";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#100F12",
    border: "1px solid rgba(238, 44, 27, 0.10)",
    boxShadow: 24,
    p: "1rem 1.5rem",
    borderRadius: "12px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

export default function StepperModal({ open, setOpen, swap, activeStep,data, claim, execute }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
            <Modal
                open={open}
                sx={{
                    background: "rgba(217, 217, 217, 0.01)",
                    backdropFilter: "blur(4px)",
                }}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CardMedia component='img' image={logo2} sx={{ width: 'auto', height: '1.5rem', marginBottom: '3rem' }} />
                    <HorizontalLinearAlternativeLabelStepper swap={swap} activeStep={activeStep} />
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        {
                            activeStep === 1 ? <Button onClick={() => execute(data?._id, data?.inscriptionId)} sx={{ display: 'flex', marginTop:'1.3rem', textTransform: 'none', alignItems: 'center', color: 'white', backgroundColor: '#2E6FEF', cursor: 'pointer' }} >
                                <Icon icon="jam:subtraction" style={{ fontSize: '1rem', marginRight: '10px' }} />
                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter' }}>Execute</Typography></Button>
                                : ''
                        }
                        {
                            activeStep === 3 ? <Button onClick={() => claim(data?._id)} sx={{ display: 'flex', marginTop:'1.3rem', textTransform: 'none', alignItems: 'center', color: 'white', backgroundColor: '#EB2EEF', cursor: 'pointer' }} >
                                <Icon icon="academicons:acclaim" style={{ fontSize: '1.2rem', marginRight: '10px', color: 'white' }} />
                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: 'white' }}>Claim</Typography>
                            </Button>
                                : ''
                        }
                    </Box> */}
                </Box>
            </Modal>
        </div>
    );
}
