import httpCommon from "../http-common";

export const swapDeposit = (data) => {
    return httpCommon.post(`/swap/deposit`, data);
  };

export const confirmDeposit = (id, data) => {
  return httpCommon.put(`/swap/deposit/confirm/${id}`, data);
};

export const initExecute = (id, data) => {
  return httpCommon.put(`swap/deposit/execute/${id}`, data)
}

export const initClaim = (id) => {
  return httpCommon.put(`swap/deposit/claim/init/${id}`)
}

export const confirmClaim = (id, data) => {
  return httpCommon.put(`swap/deposit/claim/confirm/${id}`, data)
}