import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CardMedia, Grid } from '@mui/material';
import logo from '../../images/logo.png'
import button from '../../images/button.png'
import { Link } from 'react-scroll';

const drawerWidth = 240;
const btn = { color: '#fff', fontFamily: 'Roboto Mono', fontWeight: '300', fontSize: '12px', paddingX: '10px' }

function DrawerAppBar(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [act, setA] = React.useState(true)
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', height:'96%', padding: '10%', position: 'relative', backgroundColor: 'black' }}>
      <CardMedia component='img' image={logo} sx={{ height: { md: '3rem', xs: '2rem' }, width: 'auto' }} />
      <Divider sx={{ borderColor: '#EC5F524c', marginTop: '5%' }} />
      <Box sx={{display:'flex', height:'100%', flexDirection:'column', justifyContent:'space-between'}}>
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={btn}>
            <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Home'} spy={true} smooth={true}>
              <ListItemText primary='HOME' />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={btn}>
            <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'App'} spy={true} smooth={true}>
              <ListItemText primary='APP' />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={btn}>
            <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Core'} spy={true} smooth={true}>
              <ListItemText primary='CORE OFFERINGS' />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={btn}>
            <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Tools'} spy={true} smooth={true}>
              <ListItemText primary='TOOLS' />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={btn}>
            <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Features'} spy={true} smooth={true}>
              <ListItemText primary='FEATURES' />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={btn}>
            <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'How'} spy={true} smooth={true}>
              <ListItemText primary='HOW IT WORKS' />
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
      <Button size='small' sx={{ backgroundImage: ` url(${button})`, backgroundSize: '100% 100%', height: { xl: '4rem', lg: '3rem', md:'3rem', xs:'3rem' }, width: '100%', py: '0', backgroundRepeat: 'no-repeat', px: '10px', fontSize: { xl: '0.75rem', lg: '12px' }, color: 'white' }}>Launch APP</Button>
      </Box>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ bgcolor: 'transparent', px: 2, height: { xl: '10rem', lg:'10rem', md: '10rem', sm:'7%', xs: '7%' }, position: 'absolute', boxShadow: 'none' }}>
        <Toolbar sx={{ height: '100%' }}>
          <Grid container >
            <Grid item lg={2.6} md={2.6} sm={2.6} xs={3.5} sx={{ width: '22%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CardMedia component='img' image={logo} sx={{ height: {xl:'60%',lg:'50%', md: '50%', xs: '40%' }, width: 'auto' }} />
            </Grid>
            <Grid item lg={0.4} md={0} sm={0} />
            <Grid item lg={7} md={7.4} sm={9}>
              <Box sx={{ display: { xs: 'none', sm: 'none', md:'flex' }, alignItems: 'center' }}>
                {/* <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} onSetActive={() => setA(true)} onSetInactive={() => setA(false)} to={'Home'} spy={true} smooth={true}> */}
                  <Button sx={ { ...btn,fontWeight: '400' } }>
                    Home
                  </Button>
                {/* </Link>
                <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'App'} spy={true} smooth={true}> */}
                  <Button sx={ { ...btn,fontWeight: '400' } }>APP</Button>
                {/* </Link>
                <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Core'} spy={true} smooth={true}> */}
                  <Button sx={ { ...btn,fontWeight: '400' } }>CORE OFFERINGS</Button>
                {/* </Link>
                <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Tools'} spy={true} smooth={true}> */}
                  <Button sx={ { ...btn,fontWeight: '400' } }>&nbsp;TOOLS</Button>
                {/* </Link>
                <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'Features'} spy={true} smooth={true}> */}
                  <Button sx={ { ...btn,fontWeight: '400' } }>&nbsp;FEATURES</Button>
                {/* </Link>
                <Link activeStyle={{ ...btn, border: '5px solid', borderImage: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) 1', borderWidth: ' 0 0 1px 0' }} to={'How'} spy={true} smooth={true}> */}
                  <Button sx={ { ...btn,fontWeight: '400' } }>&nbsp;HOW IT WORKS</Button>
                {/* </Link> */}
              </Box>
            </Grid>
            <Grid item md={2} sm={0.4} sx={{ display: { md: 'flex', sm:'none', xs: 'none' }, justifyContent: 'flex-end' }}>
              <Button size='small' sx={{ backgroundImage: ` url(${button})`, backgroundSize:'100% 100%', height: '3rem', py: '0', backgroundRepeat: 'no-repeat', px: '20px', fontSize: '12px', color: 'white', marginTop:'-0.6rem' }}>Launch APP</Button>
            </Grid>
          </Grid>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md:'none' } }}
          >
            <MenuIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'none' }, height:'100%', zIndex:'100000000',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: 'black' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: { md: 5, xs: 0 }, width: '100%', overflow: 'hidden', position: 'absolute' }}>
        {children}
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
