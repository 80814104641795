import React, { useState } from 'react';
import {Box, Typography, Modal, CardMedia, Divider, Button} from '@mui/material';
import { Icon } from "@iconify/react";
import eth2ab from '../../images/eth2ab.png'
import btcab from '../../images/btcab.png'


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#100F12",
    border: "1px solid rgba(238, 44, 27, 0.10)",
    boxShadow: 24,
    p: "1rem 1.5rem",
    borderRadius: "12px",
};

export default function ConfirmationModal({ open, setOpen, swap, amount,swapTapToEth, swapEthToTap, setLoading }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setLoading(false)
        setOpen(false)};

    return (
        <div>
            <Modal
                sx={{
                    background: "rgba(217, 217, 217, 0.01)",
                    backdropFilter: "blur(4px)",
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            variantMapping="p"
                            sx={{
                                fontFamily: "Inter",
                                color: "white",
                                fontWeight: "400",
                                fontSize: "13px",
                            }}
                        >
                            Swap Confirmation
                        </Typography>
                        <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                            <Icon icon="oui:cross" />
                        </Box>
                    </Box>
                    <Box sx={{marginTop:'1.3rem'}}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography
                                    variantMapping="p"
                                    sx={{
                                        fontFamily: "Inter",
                                        color: "grey",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                    }}
                                >
                                    Price:
                                </Typography>
                                <Typography
                                    variantMapping="h4"
                                    sx={{
                                        fontFamily: "Inter",
                                        color: "white",
                                        fontWeight: "500",
                                        marginBottom: "1rem",
                                    }}
                                >
                                {amount} Token
                                    {/* {data?.amount} */}
                                </Typography>
                            </Box>
                            {swap ?  <CardMedia
                                    component="img"
                                    image={btcab}
                                    sx={{ height: "2rem", width: "auto" }}
                                /> : <Box
                                sx={{
                                    bgcolor: "white",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "2rem",
                                    width: "2rem"
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={eth2ab}
                                    sx={{ height: "1rem", width: "auto" }}
                                />
                            </Box>}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography
                                    variantMapping="p"
                                    sx={{
                                        fontFamily: "Inter",
                                        color: "grey",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                    }}
                                >
                                    Fees:
                                </Typography>
                                <Typography
                                    variantMapping="h4"
                                    sx={{
                                        fontFamily: "Inter",
                                        color: "white",
                                        fontWeight: "500",
                                        marginBottom: "1rem",
                                    }}
                                >
                                {swap ? '0' : '0.01'} ETH
                                    {/* {data?.amount} */}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    bgcolor: "white",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "2rem",
                                    width: "2rem"
                                }}
                            >
                            <Box
                                sx={{
                                    bgcolor: "white",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "2rem",
                                    width: "2rem"
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={eth2ab}
                                    sx={{ height: "1rem", width: "auto" }}
                                />
                            </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{borderBottom: '1px solid rgba(255, 255, 255, 0.10)'}} />
                    <Box sx={{marginTop:'1.3rem'}}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography
                                    variantMapping="p"
                                    sx={{
                                        fontFamily: "Inter",
                                        color: "grey",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                    }}
                                >
                                    You Receive:
                                </Typography>
                                <Typography
                                    variantMapping="h4"
                                    sx={{
                                        fontFamily: "Inter",
                                        color: "white",
                                        fontWeight: "500",
                                        marginBottom: "1rem",
                                    }}
                                >
                                {amount} Token
                                    {/* {data?.amount} */}
                                </Typography>
                            </Box>
                            
                            {!swap ?  <CardMedia
                                    component="img"
                                    image={btcab}
                                    sx={{ height: "2rem", width: "auto" }}
                                /> : <Box
                                sx={{
                                    bgcolor: "white",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "2rem",
                                    width: "2rem"
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={eth2ab}
                                    sx={{ height: "1rem", width: "auto" }}
                                />
                            </Box>}
                        </Box>
                    </Box>
                    <Box sx={{marginTop:'1.3rem', display:'flex', justifyContent:'space-between'}}>
                        <Button size='small' onClick={handleClose} sx={{textTransform:'none', fontFamily:'Roboto Mono', color:'#F5872A',border: '1px solid #F5872A', padding:'0.3rem 1rem'}}>Cancel</Button>
                        <Button size='small' onClick={swap ? swapTapToEth : swapEthToTap} sx={{textTransform:'none', fontFamily:'Roboto Mono', color:'white', padding:'0.3rem 1rem',background: 'linear-gradient(90.01deg, #F5872A 0.01%, #E84C63 99.99%)'}}>Confirm</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
