import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Modal,
    TextField,
    InputAdornment,
    IconButton,
    Tab,
    Tabs,
    CardMedia,
    Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import eth2ab from "../../images/eth2ab.png";
import { getAllTokens } from "../../services/GetWebSpecificRequests";
import errorHandler from "../../helpers/errorHandler";
import { showAddress } from "../../helpers/check";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#100F12",
    border: "1px solid rgba(238, 44, 27, 0.10)",
    boxShadow: 24,
    p: "1rem 1.5rem",
    borderRadius: "12px",
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{
                maxHeight: 210, '&::-webkit-scrollbar': {
                    width: '4px'
                },

                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#343434 !important',
                    borderRadius: '50px'
                },

                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#D9D9D9',
                    borderRadius: '10px',
                }, overflowY: 'scroll'
            }}
        >
            {value === index && (
                <Box sx={{ paddingX: 1 }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicModal({ open, setOpen, selectToken, setSelectToken,setTicker, setTickerName }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = useState(0);
    const [tokens, setTokens] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const func = async () => {
            try {
                const res = await getAllTokens()
                console.log(res.data)
                setTokens(res.data)
            } catch (error) {
                errorHandler(error?.response?.data?.error)
            }
        }
        func()
    }, [])


    return (
        <div>
            <Modal
                sx={{
                    background: "rgba(217, 217, 217, 0.01)",
                    backdropFilter: "blur(4px)",
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            variantMapping="p"
                            sx={{
                                fontFamily: "Inter",
                                color: "white",
                                fontWeight: "400",
                                fontSize: "13px",
                            }}
                        >
                            Select Token
                        </Typography>
                        <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                            <Icon icon="oui:cross" />
                        </Box>
                    </Box>
                    <TextField
                        size="small"
                        id="outlined-basic"
                        fullWidth
                        placeholder="Search name or paste address"
                        variant="outlined"
                        InputProps={{
                            sx: {
                                "&:before": {
                                    borderBottom: "none",
                                },
                                "&:after": {
                                    borderBottom: "none",
                                },
                                "&:hover:not(.Mui-disabled):before": {
                                    borderBottom: "none",
                                },
                                "&.MuiOutlinedInput-root": {
                                    background: "rgba(255, 255, 255, 0.08)",
                                    color: "white",
                                    borderRadius: "12px",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    marginTop: "3%",
                                },
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end">
                                        <Icon
                                            icon="iconamoon:search-bold"
                                            style={{
                                                fontSize: "16px",
                                                color: "rgba(189, 189, 190, 1)",
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={{ width: "100%", marginTop: "0.8rem" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                sx={{ height: "30px" }}
                            >
                                <Tab
                                    sx={{
                                        "&.MuiTab-root": {
                                            color: "rgba(255, 255, 255, 0.8) !important",
                                            fontSize: "13px",
                                            fontFamily: "Inter",
                                            textTransform: "none",
                                            alignItems: "flex-start",
                                            fontWeight: "200",
                                            padding: "0",
                                        },
                                        "&.MuiButtonBase-root": {
                                            // borderBottom: "2px solid #39373C !important",
                                            justifyContent: "center !important",
                                        },
                                        "&.Mui-selected": {
                                            color: "white !important",
                                            fontWeight: "300 !important",
                                        },
                                        width: "100%",
                                        textAlign: "left !important",
                                    }}
                                    label="Tokens"
                                />
                                {/* <Tab
                                    sx={{
                                        "&.MuiTab-root": {
                                            color: "rgba(255, 255, 255, 0.8) !important",
                                            fontSize: "13px",
                                            fontFamily: "Inter",
                                            textTransform: "none",
                                            fontWeight: "200",
                                            borderBottom: "2px solid #39373C",
                                        },
                                        "&.MuiButtonBase-root": {
                                            borderBottom: "2px solid #39373C !important",
                                        },
                                        "&.Mui-selected": {
                                            color: "white !important",
                                            fontWeight: "300",
                                        },
                                        width: "50%",
                                    }}
                                    label="All Tokens"
                                    {...a11yProps(1)}
                                /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {
                                tokens.map((t, i) => {
                                    return <Grid onClick={() => {
                                        setSelectToken(t)
                                        setTicker(t?._id)
                                        setTickerName(t?.tick)
                                        handleClose()
                                    }} container sx={{  paddingY: '0.5rem' }}>
                                        <Grid item md={9} sx={{ display: 'flex', alignItems: 'center' }}>

                                            {t?.image ? <CardMedia
                                                component="img"
                                                image={t?.image}
                                                sx={{ height: "2rem", width: "auto" }}
                                            /> : <Box sx={{ height: "2rem", width: "2rem", bgcolor:'#ffffee5c', borderRadius:'50px', marginRight:'10px' }}></Box>
                                            }
                                            <Box>
                                                <Typography variantMapping='h6' sx={{ fontFamily: 'Inter', color: 'white', fontWeight: '400', fontSize: '1rem' }}>{t?.tick}</Typography>
                                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>{showAddress(t?.tokenAddress)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Balance</Typography>
                                            <Typography variantMapping='h6' sx={{ fontFamily: 'Inter', color: 'white', fontWeight: '400', fontSize: '1rem' }}>0</Typography>
                                        </Grid>
                                    </Grid>
                                })
                            }

                            
                            {/* <Grid container sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.10)', paddingY: '0.5rem' }}>
                                <Grid item md={9} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            bgcolor: "white",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "2rem",
                                            width: "2rem",
                                            marginRight: '10px'
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            image={eth2ab}
                                            sx={{ height: "1rem", width: "auto" }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variantMapping='h6' sx={{ fontFamily: 'Inter', color: 'white', fontWeight: '400', fontSize: '1rem' }}>ETH</Typography>
                                        <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Ethereum</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                                    <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Balance</Typography>
                                    <Typography variantMapping='h6' sx={{ fontFamily: 'Inter', color: 'white', fontWeight: '400', fontSize: '1rem' }}>0</Typography>
                                </Grid>
                            </Grid> */}
                        </CustomTabPanel>
                        {/* <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel> */}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
