import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { makeStyles } from '@mui/styles'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import eth from '../../images/eth.png'
import { Icon } from "@iconify/react";
import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import { getUserSwaps } from '../../services/UserSpecificRequests';
import { useGeneralDataContext } from '../../hooks/useGeneralDataContext';
import errorHandler from '../../helpers/errorHandler';
import { formatDateString, showAddress } from '../../helpers/check';
import { confirmClaim, confirmDeposit, initClaim, initExecute } from '../../services/TapToEth';
import contractABI from '../../abis/TapRouter.json'
import { getGlobalData } from '../../services/GetWebSpecificRequests';
import { ethers } from "ethers";
import btcab from '../../images/btcab.png'
import StepperModal from '../modals/StepperModal';
import successHandler from './../../helpers/successHandler';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 400,
    },
    thead: {
        border: '1px solid rgba(214, 214, 214, 0.04) !important',
        background: 'rgba(214, 214, 214, 0.04) !important',
        backdropFilter: 'blur(12px) !important',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em',
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'rgba(255, 255, 255, 0.50)',
        fontFamily: 'Roboto Mono'
    },
    [`&.${tableCellClasses.body}`]: {
        color: theme.palette.common.white,
        fontSize: 14,
    },
    [`&.${tableCellClasses.root}`]: {
        borderBottom: "none"
    }
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        border: '1px solid rgba(214, 214, 214, 0.04) !important',
        background: 'rgba(214, 214, 214, 0.04) !important',
        backdropFilter: 'blur(12px) !important',
        clipPath: 'inset(0% 0% 0% 0% round 1em)'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    fontFamily: 'Inter'
}));



export default function TableDetails() {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const { ethAccount, btcAccount } = useGeneralDataContext()
    const unisat = window.unisat;
    const [activeStep, setActiveStep] = useState(1)
    const [onConfirm, setOnConfirm] = useState(false);
    const [onClickData, setOnClickData] = useState(null)

    useEffect(() => {
        setActiveStep(onClickData?.type === 'Deposit' ? (onClickData?.swapStatus === 'Initial' ? 0 : onClickData?.swapStatus === 'Pending' ? 0 : onClickData?.swapStatus === 'Inscribed' ? 1 : onClickData?.swapStatus === 'Executed' ? 2 : onClickData?.swapStatus === 'Received' ? 3 : 5)
            : (onClickData?.swapStatus === 'Pending' ? 1 : onClickData?.swapStatus === 'Initaial' ? 0 : onClickData?.swapStatus === 'Inscribed' ? 1 : 3))
    }, [onClickData])

    const func = async () => {
        setLoading(<i class="fas fa-tire-rugged    "></i>)
        try {
            const res = await getUserSwaps(ethAccount, page)
            setData(res.data)
            console.log(res.data)
            if (res.data.len === 0) {
                setPage(page - 1)
            }
        } catch (error) {
            errorHandler(error?.response?.data?.error)
        }
        setLoading(false)
    }
    useEffect(() => {
        func()
    }, [page])


    const pending = async (paymentAddress, amount, id) => {
        try {
            const txid = await unisat.sendBitcoin(paymentAddress, amount);
            console.log(txid)
            try {
                const res2 = await confirmDeposit(id, {
                    paymentTxHash: txid
                })
                console.log(res2.data)
            } catch (error) {
                errorHandler(error?.response?.data?.error)
            }
        } catch (error) {
            errorHandler("Error while depositing")
        }
    }
    console.log(btcAccount)


    const execute = async (id, inscriptionId) => {
        try {
            setOnConfirm(true)
            const txid = await unisat.sendInscription(btcAccount, inscriptionId)
            console.log(txid)
            const time = setTimeout(() => { }, 5000)
            const res2 = await initExecute(id, { txId: txid })
            setActiveStep(2)
            func()
            successHandler("Verifying tap transfer, Please check after an hour")
            console.log(res2)
        } catch (error) {
            errorHandler(error?.reason)
        }
    }

    const claim = async (id) => {
        setOnConfirm(true)
        const res = await getGlobalData();
        console.log(res.data);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tapRouterContract = new ethers.Contract(
            res.data.contractAddress, // Get this from the /global API
            contractABI, // abis/TapRouter.json
            signer
        );
        let chainId = await window.ethereum.request({ method: "eth_chainId" });
        chainId = parseInt(chainId, 16);
        const res2 = await initClaim(id)
        console.log(res2)
        const tx = await tapRouterContract.withdraw(res2?.data?.value?.token, res2?.data?.value?.from, res2?.data?.value?.amount, res2?.data?.value?.txHash, String(chainId), res2?.data?.signatures);
        await tx.wait();
        console.log('Transaction hash:', tx.hash);

        try {
            const resClaim = await confirmClaim(id, { txHash: tx.hash })
            console.log(resClaim)
            setActiveStep(5)
            func()
        } catch (error) {
            errorHandler(error?.response?.data?.error)
        }
    }

    console.log(onClickData)

    return (
        <>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2rem' }} >
                <Grid item sx={{ display: 'flex' }}>
                    <Box sx={page === 1 ? { color: 'grey !important', padding: '0.5rem 0.8rem', borderRadius: '6px 0px 0px 6px', cursor: 'not-allowed', backgroundColor: 'rgba(200, 200, 200, 0.2)', border: '1px solid grey', display: 'flex', justifyContent: 'center', alignContent: 'center' } : { color: 'white !important', padding: '0.5rem 0.8rem', borderRadius: '6px 0px 0px 6px', backgroundColor: 'rgba(245, 135, 42, 0.2)', border: '1px solid rgba(245, 135, 42, 1)', display: 'flex', justifyContent: 'center', alignContent: 'center', cursor: 'pointer' }} onClick={() => page !== 1 && setPage(page - 1)}><Icon icon="grommet-icons:form-previous" /></Box>
                    <Typography variantMapping='p' sx={{ fontSize: '12px', width: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid rgba(245, 135, 42, 1)', fontFamily: 'Inter' }}>{page}</Typography>
                    <Box sx={data.length < 10 ? { color: 'grey !important', padding: '0.5rem 0.8rem', borderRadius: '0px 6px 6px 0px', cursor: 'not-allowed', backgroundColor: 'rgba(200, 200, 200, 0.2)', border: '1px solid grey', display: 'flex', justifyContent: 'center', alignContent: 'center' } : { color: 'white !important', padding: '0.5rem 0.8rem', borderRadius: '0px 6px 6px 0px', backgroundColor: 'rgba(245, 135, 42, 0.2)', border: '1px solid rgba(245, 135, 42, 1)', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignContent: 'center' }} onClick={() => data.length === 10 && setPage(page + 1)}><Icon icon="grommet-icons:form-next" /></Box>
                </Grid>

            </Grid>

            <TableContainer component={Paper} sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                <Table className={classes.table} sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead classes={{ root: classes.thead }} >
                        <TableRow classes={{ root: classes.thead }}>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell align="left">Token</StyledTableCell>
                            <StyledTableCell align="left">From</StyledTableCell>
                            <StyledTableCell align="left">To</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? <StyledTableRow >
                                <StyledTableCell colSpan={5} component="th" scope="row">
                                    <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', display: 'flex', justifyContent: 'center' }}>loading...</Typography>
                                </StyledTableCell>
                            </StyledTableRow> :
                                data.map((d, i) => {
                                    return <StyledTableRow onClick={() => {
                                        setOnClickData(d)
                                        setOnConfirm(true)
                                    }}>

                                        <StyledTableCell component="th" scope="row">
                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter' }}>{formatDateString(d?.updatedAt)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter' }}>{d?.token?.tick}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CardMedia component='img' image={d?.type === 'Deposit' ? btcab : eth} sx={{ height: '1rem', marginRight: '10px', width: 'auto' }} />
                                                <Typography variantMapping='p' sx={{ marginRight: '10px', fontSize: '12px', fontFamily: 'Inter' }}>{showAddress(d?.type === 'Deposit' ? d?.userBtcAddress : d?.userEthAddress)}</Typography>
                                                <Icon onClick={() =>  window.open(d?.type === 'Deposit' ?  `https://mempool.space/address/${btcAccount}` : `https://sepolia.etherscan.io/address/${ethAccount}` , '_blank')} icon="octicon:link-external-24" style={{ fontSize: '0.9rem', cursor:'pointer' }} />
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CardMedia component='img' image={d?.type === 'Deposit' ? eth : btcab} sx={{ height: '1rem', marginRight: '10px', width: 'auto' }} />
                                                <Typography variantMapping='p' sx={{ marginRight: '10px', fontSize: '12px', fontFamily: 'Inter' }}>{showAddress(d?.type === 'Deposit' ? d?.userEthAddress : d?.userBtcAddress)}</Typography>
                                                <Icon onClick={() =>  window.open(d?.type === 'Deposit' ?   `https://sepolia.etherscan.io/address/${ethAccount}` : `https://mempool.space/address/${btcAccount}`  , '_blank')} icon="octicon:link-external-24" style={{ fontSize: '0.9rem', cursor:'pointer' }} />
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {/* <Icon icon="tabler:clock" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EF2E2E' }} /> */}
                                                {d?.type === 'Deposit' ?
                                                    d?.swapStatus === 'Initial' ?
                                                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'not-allowed' }} >
                                                            <Icon icon="charm:cross" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EF2E2E' }} />
                                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EF2E2E' }}>Failed</Typography></Box> :


                                                        d?.swapStatus === 'Pending' ?
                                                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'not-allowed' }} >
                                                                <Icon icon="charm:cross" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EF2E2E' }} />
                                                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EF2E2E' }}>Aborted</Typography></Box> :

                                                            d?.swapStatus === 'Inscribed' ?
                                                                <Button onClick={() => execute(d?._id, d?.inscriptionId)} sx={{ display: 'flex', textTransform: 'none', alignItems: 'center', color: 'white', backgroundColor: '#2E6FEF', cursor: 'pointer' }} >
                                                                    <Icon icon="jam:subtraction" style={{ fontSize: '1rem', marginRight: '10px' }} />
                                                                    <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter' }}>Execute</Typography></Button> :

                                                                d?.swapStatus === "Executed" ?
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'wait' }} >
                                                                        <Icon icon="tabler:clock" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EFD02E' }} />
                                                                        <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EFD02E' }}>Verifying Transfer</Typography></Box> :

                                                                    d?.swapStatus === "Paid" ?
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'wait' }} >
                                                                            <Icon icon="tabler:clock" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EF732E' }} />
                                                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EF732E' }}>Verifying Inscription</Typography></Box> :


                                                                        d?.swapStatus === 'Received' ?
                                                                            <Button onClick={() => claim(d?._id)} sx={{ display: 'flex', textTransform: 'none', alignItems: 'center', color: 'white', backgroundColor: '#EB2EEF', cursor: 'pointer' }} >

                                                                                <Icon icon="academicons:acclaim" style={{ fontSize: '1.2rem', marginRight: '10px', color: 'white' }} />
                                                                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: 'white' }}>Claim</Typography>
                                                                            </Button> :

                                                                            <><Icon icon="prime:check-circle" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#3DEF2E' }} />
                                                                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#3DEF2E' }}>Completed</Typography></>

                                                    : d?.swapStatus === 'Pending' ? d?.isFailed ?
                                                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'not-allowed' }} >
                                                            <Icon icon="charm:cross" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EF2E2E' }} />
                                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EF2E2E' }}>Aborted</Typography></Box>
                                                        : <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'wait' }} >
                                                            <Icon icon="tabler:clock" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EFD02E' }} />
                                                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EFD02E' }}>Verifying Transfer</Typography></Box>
                                                        : d?.swapStatus === "Inscribed" ?
                                                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'wait' }} >
                                                                <Icon icon="tabler:clock" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#EFD02E' }} />
                                                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#EFD02E' }}>Verifying Transfer</Typography></Box>
                                                            : <><Icon icon="prime:check-circle" style={{ fontSize: '1.2rem', marginRight: '10px', color: '#3DEF2E' }} />
                                                                <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#3DEF2E' }}>Completed</Typography></>
                                                }

                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                })
                        }
                    </TableBody>
                </Table>

            </TableContainer>
            <StepperModal open={onConfirm}
                setOpen={setOnConfirm}
                swap={onClickData?.type === 'Deposit' ? true : false}
                activeStep={activeStep}
                data={onClickData}
                claim={claim}
                execute={execute}
            />
        </>
    );
}
