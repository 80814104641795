import axios from "axios";
import { isMainnetUrl } from './helpers/check';


export default axios.create({
  baseURL: "/api/v1",


  headers: {
    // "Authorization": `Bearer ${localStorage.getItem("token")}`,
    "Content-type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  mode: "cors"
});