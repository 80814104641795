import React from 'react'
import ResponsiveDrawer from '../components_dashboard/sidebar/Drawer'
import AssettBridge from '../components_dashboard/asset_bridge/AssettBridge'

export default function PageAssetBridge() {
  return (
    <ResponsiveDrawer>
        <AssettBridge/>
    </ResponsiveDrawer>
  )
}
