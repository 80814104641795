import { Box, Button, CardMedia, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import eth2ab from '../../images/eth2ab.png'
import { Icon } from "@iconify/react";

export default function From({ open, setOpen, amount, setAmount, swap, selectToken, balance }) {

    return (
        <>
            <Grid container sx={{ borderRadius: '1rem', border: '1px solid rgba(214, 214, 214, 0.04)', padding: '1rem', border: '1px solid rgba(214, 214, 214, 0.04)', background: 'rgba(214, 214, 214, 0.04)', backdropFilter: 'blur(12px)' }}>
                <Grid item md={1.8} sx={{ bgcolor: 'rgba(255, 255, 255, 0.08)', padding: '0.5rem', display: 'flex', gap: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '1rem' }}>
                    <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Network</Typography>
                    <Box sx={{ bgcolor: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', width: '3rem' }}>
                        <CardMedia component='img' image={eth2ab} sx={{ height: '1.6rem', width: 'auto' }} />
                    </Box>
                    <Typography variantMapping='h4' sx={{ fontFamily: 'Inter', color: 'white', fontWeight: '500' }}>ETH</Typography>
                </Grid>
                <Grid item md={0.1}></Grid>
                <Grid item md={10.1}>
                    <Grid container sx={{ padding: '0.5rem' }}>
                        <Grid item md={12} sx={{ bgcolor: 'rgba(255, 255, 255, 0.08)', display: 'flex', flexDirection: 'column', borderRadius: '1rem', marginBottom: '1rem', padding: '0.5rem 0.8rem' }}>
                            <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Asset</Typography>
                            <Box onClick={() => setOpen(true)} sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', marginTop: '0.5rem' }}>
                                {selectToken ? <>
                                    {selectToken?.image ? <CardMedia
                                        component="img"
                                        image={selectToken?.image}
                                        sx={{ height: "1.2rem", width: "auto" }}
                                    /> : <Icon icon="material-symbols-light:circle" style={{ color: '#635355', marginRight: '4px', fontSize: '1.2rem' }} />}
                                    <Typography variantMapping='p' sx={{ fontSize: '13px', padding: '0', margin: '0', fontFamily: 'Inter', color: 'rgba(255, 255, 255, 1)' }}>{selectToken?.tick}</Typography>

                                </> : <>
                                    <Icon icon="material-symbols-light:circle" style={{ color: '#635355', marginRight: '4px', fontSize: '1.2rem' }} />
                                    <Typography variantMapping='p' sx={{ fontSize: '13px', padding: '0', margin: '0', fontFamily: 'Inter', color: 'rgba(255, 255, 255, 1)' }}>Select</Typography>
                                </>}
                            </Box>
                        </Grid>
                        <Grid item md={12} >
                            <Grid container>
                                <Grid item md={8.8} sx={{ bgcolor: 'rgba(255, 255, 255, 0.08)', position: 'relative', borderRadius: '1rem', padding: '0.5rem 0.8rem' }}>
                                    <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Amount</Typography>
                                    <TextField
                                        require
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        size="small"
                                        InputProps={{
                                            sx: {
                                                '&:before': {
                                                    borderBottom: 'none',
                                                },
                                                '&:after': {
                                                    borderBottom: 'none',
                                                },
                                                '&:hover:not(.Mui-disabled):before': {
                                                    borderBottom: 'none',
                                                },
                                            },
                                        }}
                                        sx={{ width: 'auto', marginTop: '0.5rem', '& .MuiInput-input': { color: 'white !important', fontSize: '13px', fontFamily: 'Inter', fontWeight: '400' } }}
                                        id="standard-required"
                                        placeholder='_____'
                                        variant="standard"
                                    />
                                    <Button onClick={() => setAmount(balance)} sx={{ borderRadius: '50px', fontFamily: 'Inter', textTransform: 'none', height: '1.4rem', minWidth: '50px', color: 'white', background: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%)', position: 'absolute', top: '10px', right: '10px' }}>Max</Button>
                                </Grid>
                                <Grid item md={0.2} />
                                <Grid item md={3} sx={{ padding: '0.5rem 0.8rem' }}>
                                    <Typography variantMapping='p' sx={{ fontSize: '12px', fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.50)' }}>Balance</Typography>
                                    <Typography variantMapping='h4' sx={{ fontFamily: 'Inter', color: 'white', fontWeight: '500' }}>{balance ? balance : '____'}</Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
