import React, { useContext, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import MainDashPage from '../pages/MainDashPage';
import PageAssetBridge from '../pages/PageAssetBridge';


export default function MainRouter() {
    // function PrivateRouter() {
    //     const { user, setUser, setOpen } = useContext(offerContext)
    //     return user !== null ? <>
    //         <Outlet />
    //     </> : <>
    //         {
    //             JSON.parse(localStorage.getItem("deOffersUser")) === null && setOpen(true)
    //         }
    //         <Navigate to="/" />
    //     </>
    // }
    // const { user, setUser,  allOffers, allCommunities,  allAds, createOfferFromApp, buyOfferFromApp, offerSummary } = useContext(offerContext)
    // useEffect(() => {
    //     setUser(JSON.parse(localStorage.getItem("deOffersUser")))
    // }, [])

    return (
        <>
            <Routes>
                <Route exact path='/' element={<LandingPage/>} />
                <Route exact path='/dashboard' element={<MainDashPage/>} />
                <Route exact path='/assetbridge' element={<PageAssetBridge/>} />

                
                {/* <Route path='/redeemoffer' element={<PrivateRouter />} >
                    <Route exact path='/redeemoffer' element={<BurnNFTPage />} />
                </Route> */}
                
            </Routes>
        </>
    )
}