import httpCommon from "../http-common";
import tokenContractABI from '../abis/TapBridgeERC20.json'
import { ethers } from "ethers";
import contractABI from '../abis/TapRouter.json'
import { getGlobalData } from "./GetWebSpecificRequests";

export const withdraw = (data) => {
    return httpCommon.post(`/swap/withdraw`, data);
};



export const contract = async (tokenAddress, amount, ethAccount) => {
    // console.log(amount, typeof(amount))
    const res = await getGlobalData();
    console.log(res.data);

    if (!window.ethereum) {
        throw new Error("No Ethereum provider found");
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const tokenContract = new ethers.Contract(tokenAddress, tokenContractABI, signer);
    // const amountBN = ethers.utils.parseUnits(amount.toString(), 18); // Adjust the number of decimals if necessary

    try {
        const tx = await tokenContract.approve(res.data.contractAddress, amount);
        await tx.wait();
        console.log('Approval transaction hash:', tx.hash);
    } catch (error) {
        console.error("Approval failed:", error);
        throw new Error("Token approval failed");
    }

    const tapRouterContract = new ethers.Contract(
        res.data.contractAddress, // Get this from the /global API
        contractABI, // abis/TapRouter.json
        signer
    );

    let chainId = await window.ethereum.request({ method: "eth_chainId" });
    chainId = parseInt(chainId, 16);

    const ethValue = ethers.utils.parseEther('0.01'); // 0.01 ETH

    try {
        const tx2 = await tapRouterContract.deposit(tokenAddress, ethAccount, amount, chainId, { value: ethValue });
        await tx2.wait();
        console.log('Deposit transaction hash:', tx2.hash);
        return tx2.hash;
    } catch (error) {
        console.error("Deposit failed:", error);
        if (error.code === 'CALL_EXCEPTION') {
            console.error("Call exception occurred. Check the contract logic or parameters.");
        } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
            console.error("Unpredictable gas limit error. Try adjusting the gas limit.");
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw new Error("Deposit transaction failed");
    }
};