import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

import { chains, chainsTestnet, isMainnetUrl } from './helpers/check.js';

const projectId = "90e7c73b4f9db61c5369a2bd03c53549";
const isMainnet = isMainnetUrl();

// WC modal
const metadata = {
  name: "Ordigen",
  description: "Cross Chain Swap",
  url: "https://ordigen.tech/",
};
const metadataTestnet = {
  name: "Ordigen Testnet",
  description: "Cross Chain Swap",
  url: "https://testnet.ordigen.tech/",
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: isMainnet ? metadata : metadataTestnet,
    defaultChainId: isMainnet ? chains[0].chainId : chainsTestnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
  }),
  chains: isMainnet ? chains : chainsTestnet,
  projectId,
});

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
