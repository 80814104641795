export function isMainnetUrl() {
  return (
    !window.location.href.includes("localhost") &&
    !window.location.href.includes("127.0.0.1:5173") &&
    !window.location.href.includes("testnet")
  );
}

export const chains = [
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://eth-pokt.nodies.app",
  },
  // {
  //   chainId: 12009,
  //   name: "SatoshiVM",
  //   currency: "BTC",
  //   explorerUrl: "https://svmscan.io/",
  //   rpcUrl: "https://mainnet-rpc.satoshichain.io",
  // },
  {
    chainId: 137,
    name: "Polygon",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com",
    rpcUrl: "https://polygon-bor-rpc.publicnode.com",
  },
  {
    chainId: 56,
    name: "Binance",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-pokt.nodies.app",
  },
];

export const chainsTestnet = [
  {
    chainId: 11155111,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://sepolia.etherscan.io",
    rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
  },
  {
    chainId: 3110,
    name: "SatoshiVM",
    currency: "BTC (SAVM)",
    explorerUrl: "https://testnet.svmscan.io",
    rpcUrl: "https://test-rpc-node-http.svmscan.io",
  },
  {
    chainId: 80001,
    name: "Polygon",
    currency: "MATIC",
    explorerUrl: "https://mumbai.polygonscan.com",
    rpcUrl: "https://polygon-mumbai-pokt.nodies.app",
  },
  {
    chainId: 97,
    name: "Binance",
    currency: "tBNB",
    explorerUrl: "https://testnet.bscscan.com",
    rpcUrl: "https://bsc-testnet-rpc.publicnode.com",
  },
];

export const showAddress = (address) =>
  address
    ? `${address.toLowerCase().substring(0, 2)}...${address.substring(
      address.length - 6,
      address.length
    )}`
    : "";


export function isValidTaprootAddress(address) {
  const isMainnet = isMainnetUrl();
  const taprootRegex = /^bc1[0-9a-zA-Z]{59}$/
  return taprootRegex.test(address);
}

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}.${month}.${day} / ${hours}:${minutes}`;
};

const BTC_TO_SAT = 100000000;
export const satoshisToBtc = (satoshis) => satoshis / BTC_TO_SAT;