import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Icon } from "@iconify/react";
import { Box, CircularProgress } from '@mui/material';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? "#7F7F7F44" : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90.01deg, #F5872A 0.01%, #E84C63 99.99%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
        '#1D9112',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? "#7F7F7F44" : '#7F7F7F44',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
backgroundColor:'#424242 !important',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(90.01deg, #F5872A 0.01%, #E84C63 99.99%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

  }),
  ...(ownerState.completed && {
    backgroundColor:
      '#1D9112',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const iconsTaptoEth = {
    1: <Icon icon="ph:hand-deposit-fill"  style={{fontSize:'30px'}} />,
    2: <Icon icon="lets-icons:send-fill"  style={{fontSize:'30px'}} />,
    3: <Icon icon="bitcoin-icons:verify-filled"  style={{fontSize:'30px'}} />,
    4: <Icon icon="academicons:acclaim"   style={{fontSize:'30px'}} />,
    5: <Icon icon="solar:check-circle-broken" style={{fontSize:'30px'}} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <Icon icon="mdi:check" style={{fontSize:'24px'}} /> : active ? <Box sx={{position:'relative'}}>
        <CircularProgress sx={{position:'absolute', top:'-0.7rem', left:'-1rem', color:'#F5872A' }} thickness={1} size={60}/>
        {iconsTaptoEth[String(props.icon)]}
        </Box> : iconsTaptoEth[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;
  
    const iconsEthtoTap = {
      1: <Icon icon="ph:hand-deposit-fill"  style={{fontSize:'30px'}} />,
      2: <Icon icon="bitcoin-icons:verify-filled"  style={{fontSize:'30px'}} />,
      3: <Icon icon="solar:check-circle-broken"  style={{fontSize:'30px'}} />
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <Icon icon="mdi:check" style={{fontSize:'24px'}} /> : active ? <Box sx={{position:'relative'}}>
        <CircularProgress sx={{position:'absolute', top:'-0.7rem', left:'-1rem', color:'#F5872A' }} thickness={1} size={60}/>
        {iconsEthtoTap[String(props.icon)]}
        </Box>
        : iconsEthtoTap[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const stepsTaptoEth = [
    'Initiating deposit',
    'Sending tap token',
    'Verifying tap transfer',
    'Claim Eth tokens',
    'Completed'
];

const stepsEthtoTap = [
    'Initiating deposit',
    'Verifying transfer',
    'Completed'
];

export default function CustomizedSteppers({swap, activeStep}) {
  return (
    <Stack sx={{ width: '100%', bgcolor:'black', borderRadius:'10px', padding:'1rem' }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {swap ? stepsTaptoEth.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        )) : stepsEthtoTap.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon2}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
