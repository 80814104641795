import React from 'react'
import DashboardDetails from '../components_dashboard/details/DashboardDetails';
import ResponsiveDrawer from './../components_dashboard/sidebar/Drawer';

export default function MainDashPage() {
  return (
    <>
        <ResponsiveDrawer>
            <DashboardDetails/>
        </ResponsiveDrawer>
    </>
  )
}
