import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import From from "./From";
import To from "./To";
import { Icon } from "@iconify/react";
import button from "../../images/button.png";
import BasicModal from "./ModalSelect";
import { confirmClaim, confirmDeposit, initClaim, initExecute, swapDeposit } from "../../services/TapToEth";
import { useGeneralDataContext } from "../../hooks/useGeneralDataContext";
import errorHandler from "../../helpers/errorHandler";
import { contract, withdraw } from "../../services/EthtoTap";
import eth2ab from "../../images/eth2ab.png";
import btcab from "../../images/btcab.png";
import ConfirmationModal from "../modals/ConfirmationModal";
import StepperModal from "../modals/StepperModal";
import { useNavigate } from 'react-router-dom';
import successHandler from "../../helpers/successHandler";
import { getGlobalData } from "../../services/GetWebSpecificRequests";
import { ethers } from "ethers";
import contractABI from '../../abis/TapRouter.json'
import tokenContractABI from '../../abis/TapBridgeERC20.json'
import { getBtcBalance } from "../../services/UserSpecificRequests";


export default function AssettBridge() {
  const navigate = useNavigate()
  const { ethAccount, btcAccount, networkId } = useGeneralDataContext();
  const [swap, setSwap] = useState(false);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(null);
  // const [tAmount, setTAmount] = useState(null)
  const [ticker, setTicker] = useState(null);
  const [tickername, setTickerName] = useState(null);
  const [selectToken, setSelectToken] = useState(null);
  console.log(selectToken, ticker, tickername);
  const [loading, setLoading] = useState(false);
  const unisat = window.unisat;
  const [onClickSwap, setOnClickSwap] = useState(false);
  const [onConfirm, setOnConfirm] = useState(false);
  const [activeStep, setActiveStep] = useState(0)
  const [onClickData, setOnClickData] = useState(null)
  const [ethBalance, setEthBalance] = useState(0)
  const [btcBalance, setBtcBalance] = useState(0)

  useEffect(() => {
    const func = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(selectToken?.tokenAddress, tokenContractABI, signer);
      
      try {
        const bal = await tokenContract.balanceOf(ethAccount);
        console.log(bal.toString()); // Convert BigNumber to string and log it
        setEthBalance(bal.toString()); // Set balance as a string in the state
      } catch (error) {
        console.error('Error fetching ETH balance:', error);
      }

      try {
        const bal2 = await getBtcBalance({ userBtcAddress: btcAccount, tickername: selectToken?.tick });
        setBtcBalance(bal2.data.balance);
      } catch (error) {
        console.error('Error fetching BTC balance:', error);
      }
    };

    if (selectToken) {
      func();
    }
  }, [selectToken, ethAccount, btcAccount, tokenContractABI]);

  const swapTapToEth = async () => {
    setOnConfirm(true)
    if (ticker && tickername) {
      const data = {
        ticker: ticker,
        tickername: tickername,
        tokenAmount: amount,
        userEthAddress: ethAccount,
        userBtcAddress: btcAccount,
        chainId: networkId,
      };
      console.log(data);
      try {
        const res = await swapDeposit(data);
        console.log(res.data);
        try {
          console.log(res.data.paymentAddress, res.data.amount);
          const txid = await unisat.sendBitcoin(
            res?.data?.paymentAddress,
            res?.data?.amount
          );
          console.log(txid);
          try {
            const res2 = await confirmDeposit(res?.data?.swapID, {
              paymentTxHash: txid,
            });
            console.log(res2.data);
            setOnClickData(res2.data)
            setActiveStep(1)
            setOnConfirm(false)
            setOnClickSwap(false)
            navigate('/dashboard')
            setLoading(false);
          } catch (error) {
            errorHandler(error?.response?.data?.error);
            setOnClickSwap(false)
            setOnConfirm(false)
            setLoading(false);
          }
        } catch (error) {
          errorHandler("Error while depositing");
          setOnClickSwap(false)
          setOnConfirm(false)
          setLoading(false);
        }
      } catch (error) {
        errorHandler(error?.response?.data?.error);
        setOnClickSwap(false)
        setOnConfirm(false)
        setLoading(false);
      }
    } else {
      errorHandler("Select a token");
      setOnClickSwap(false)
      setOnConfirm(false)
      setLoading(false);
    }
  };

  const swapEthToTap = async () => {
    setOnConfirm(true)
    try {
      const hash = await contract(
        selectToken?.tokenAddress,
        amount,
        ethAccount
      );
      console.log(hash);
      if (ticker && tickername) {
        const data = {
          ticker: ticker,
          tickername: tickername,
          tokenAmount: amount,
          userEthAddress: ethAccount,
          userBtcAddress: btcAccount,
          txHash: hash,
        };
        try {
          const res = await withdraw(data);
          console.log(res.data);
          setActiveStep(1)
          setOnClickSwap(false)
          setOnConfirm(false)
          successHandler("Verifying tap transfer, Please check after an hour")
          navigate('/dashboard')
          setLoading(false);
        } catch (error) {
          errorHandler(error?.response?.data?.error);
          setOnClickSwap(false)
          setOnConfirm(false)
          setLoading(false);
        }
      } else {
        errorHandler("Select a token");
        setOnClickSwap(false)
        setOnConfirm(false)
        setLoading(false);
      }
    } catch (error) {
      errorHandler(error?.reason);
      setOnClickSwap(false)
      setOnConfirm(false)
      setLoading(false);
    }
  };

  const execute = async (id, inscriptionId) => {
    try {
      onConfirm(true)
      const txid = await unisat.sendInscription(btcAccount, inscriptionId)
      console.log(txid)
      const time = setTimeout(() => { }, 5000)
      const res2 = await initExecute(id, { txId: txid })
      setActiveStep(2)
      successHandler("Verifying tap transfer, Please check after an hour")
      console.log(res2)
    } catch (error) {
      errorHandler(error?.reason)
    }
  }

  const claim = async (id) => {
    onConfirm(true)
    const res = await getGlobalData();
    console.log(res.data);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tapRouterContract = new ethers.Contract(
      res.data.contractAddress, // Get this from the /global API
      contractABI, // abis/TapRouter.json
      signer
    );
    let chainId = await window.ethereum.request({ method: "eth_chainId" });
    chainId = parseInt(chainId, 16);
    const res2 = await initClaim(id)
    console.log(res2)
    const tx = await tapRouterContract.withdraw(res2?.data?.value?.token, res2?.data?.value?.from, res2?.data?.value?.amount, res2?.data?.value?.txHash, String(chainId), res2?.data?.signatures);
    await tx.wait();
    console.log('Transaction hash:', tx.hash);

    try {
      const resClaim = await confirmClaim(id, { txHash: tx.hash })
      console.log(resClaim)
      setActiveStep(5)
    } catch (error) {
      errorHandler(error?.response?.data?.error)
    }
  }

  const swapClickFunc = () => {
    if (ticker && tickername) {
      setLoading(true);
      setOnClickSwap(true);
    } else {
      errorHandler("Select a token");
      setLoading(false);
    }
  };

  console.log(ethBalance, btcBalance);
  return (
    <>
      <Typography
        variantMapping="h4"
        sx={{
          fontFamily: "Inter",
          color: "white",
          fontWeight: "500",
          marginBottom: "1rem",
        }}
      >
        From
      </Typography>
      {swap ? (
        <To
          open={open}
          setOpen={setOpen}
          selectToken={selectToken}
          swap={swap}
          amount={amount}
          setAmount={setAmount}
          balance={btcBalance}
        />
      ) : (
        <From
          open={open}
          setOpen={setOpen}
          selectToken={selectToken}
          swap={swap}
          amount={amount}
          setAmount={setAmount}
          balance={ethBalance}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => setSwap(!swap)}
          sx={{
            backgroundColor: "rgba(214, 214, 214, 0.07)",
            borderRadius: "50px",
            marginTop: "2rem",
            minWidth: "34px",
            display: "flex",
            alignItems: "center",
            height: "40px",
            border: "1px solid rgba(214, 214, 214, 0.10)",
            color: "white",
          }}
        >
          <Icon icon="ic:outline-swap-vert" style={{ fontSize: "24px" }} />
        </Button>
      </Box>
      <Typography
        variantMapping="h4"
        sx={{
          fontFamily: "Inter",
          color: "white",
          fontWeight: "500",
          marginBottom: "1rem",
        }}
      >
        To
      </Typography>
      {swap ? (
        <From
          open={open}
          setOpen={setOpen}
          selectToken={selectToken}
          swap={swap}
          amount={amount}
          setAmount={setAmount}
          balance={ethBalance}
        />
      ) : (
        <To
          open={open}
          setOpen={setOpen}
          selectToken={selectToken}
          swap={swap}
          amount={amount}
          setAmount={setAmount}
          balance={btcBalance}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <Box
            sx={{
              marginTop: "1rem",
              background:
                "linear-gradient(90deg, #F5872A 0.01%, #E84C63 100%) !important",
              height: 40,
              borderRadius: "50px",
              padding: "5px",
            }}
          >
            <CircularProgress size={30} sx={{ color: "white" }} />
          </Box>
        ) : (
          <Button
            onClick={swapClickFunc}
            size="small"
            sx={{
              backgroundImage: ` url(${button})`,
              marginTop: "1rem",
              backgroundSize: "100% 100%",
              height: "3rem !important",
              py: "0",
              backgroundRepeat: "no-repeat",
              px: "10px",
              fontSize: { xl: "0.75rem", lg: "12px" },
              color: "white",
            }}
          >
            {" "}
            &nbsp; &nbsp;Swap &nbsp; &nbsp;
          </Button>
        )}
      </Box>
      <Box
        sx={{
          borderRadius: "12px",
          border: "1px solid rgba(214, 214, 214, 0.04)",
          marginTop: "5%",
          border: "1px solid rgba(214, 214, 214, 0.04)",
          background: "rgba(214, 214, 214, 0.04)",
          backdropFilter: "blur(12px)",
        }}
      >
        <Typography
          variantMapping="h4"
          sx={{
            fontFamily: "Inter",
            color: "white",
            fontWeight: "500",
            padding: "1rem 1rem 0 1rem",
            marginBottom: "1rem",
          }}
        >
          Route
        </Typography>
        <Typography
          variantMapping="p"
          sx={{
            fontSize: "12px",
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            padding: "0.6rem 1rem",
            fontFamily: "Roboto Mono",
            color: "rgba(255, 255, 255, 0.50)",
          }}
        >
          Route provided
        </Typography>
        {swap ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1rem 1rem 0 1rem",
              }}
            >
              <Typography variantMapping="p" sx={{ marginRight: "1rem" }}>
                BTC Bridge
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1rem 1rem 1rem 1rem",
              }}
            >
              <Box
                sx={{
                  borderRadius: "10px",
                  background: "#ffffff21",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={btcab}
                  sx={{ height: "1.3rem", width: "auto", marginRight: "10px" }}
                />
                <Typography variantMapping="p">BTC</Typography>
              </Box>
              <Icon
                icon="solar:arrow-right-linear"
                style={{ fontSize: "2rem", margin: "0 1rem" }}
              />
              <Typography variantMapping="p">TapBridge</Typography>
              <Icon
                icon="solar:arrow-right-linear"
                style={{ fontSize: "2rem", margin: "0 1rem" }}
              />
              <Box
                sx={{
                  borderRadius: "10px",
                  background: "#ffffff21",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    height: "1.3rem",
                    width: "1.3rem",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={eth2ab}
                    sx={{ height: "1rem", width: "auto" }}
                  />
                </Box>
                <Typography variantMapping="p">ETH</Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1rem 1rem 0 1rem",
              }}
            >
              <Typography variantMapping="p" sx={{ marginRight: "1rem" }}>
                ETH Bridge
              </Typography>
              <Typography
                variantMapping="p"
                sx={{
                  padding: "0.5rem 1rem 0.5rem 1rem",
                  fontSize: "13px",
                  borderRadius: "50px",
                  background: "#ffffff21",
                }}
              >
                Receive tokens automatically
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1rem 1rem 1rem 1rem",
              }}
            >
              <Box
                sx={{
                  borderRadius: "10px",
                  background: "#ffffff21",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    height: "1.3rem",
                    width: "1.3rem",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={eth2ab}
                    sx={{ height: "1rem", width: "auto" }}
                  />
                </Box>
                <Typography variantMapping="p">ETH</Typography>
              </Box>
              <Icon
                icon="solar:arrow-right-linear"
                style={{ fontSize: "2rem", margin: "0 1rem" }}
              />
              <Typography variantMapping="p">TapBridge</Typography>
              <Icon
                icon="solar:arrow-right-linear"
                style={{ fontSize: "2rem", margin: "0 1rem" }}
              />
              <Box
                sx={{
                  borderRadius: "10px",
                  background: "#ffffff21",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={btcab}
                  sx={{ height: "1.3rem", width: "auto", marginRight: "10px" }}
                />
                <Typography variantMapping="p">BTC</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <BasicModal
        open={open}
        setOpen={setOpen}
        selectToken={selectToken}
        setSelectToken={setSelectToken}
        setTicker={setTicker}
        setTickerName={setTickerName}
      />
      <ConfirmationModal
        open={onClickSwap}
        setOpen={setOnClickSwap}
        swap={swap}
        amount={amount}
        swapTapToEth={swapTapToEth}
        swapEthToTap={swapEthToTap}
        setLoading={setLoading}
      />

      <StepperModal open={onConfirm}
        setOpen={setOnConfirm}
        swap={swap}
        activeStep={activeStep}
        data={onClickData}
        claim={claim}
        execute={execute}
      />
    </>
  );
}
