import './App.css';
import { DashContext } from './context/DashContext';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loading from './components_dashboard/loader/Loading';
import MainRouter from './router/MainRouter';
import { GeneralDataProvider } from './context/GeneralDataProvider';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <GeneralDataProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        {/* {
          loadOnAccChange ? <Loading /> :  */}
          <MainRouter />
        {/* } */}
      </Router>
    </GeneralDataProvider>
  );
}

export default App;
