import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { isMainnetUrl, showAddress } from "../../helpers/check";
import { useGeneralDataContext } from "../../hooks/useGeneralDataContext";
import errorHandler from './../../helpers/errorHandler';
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { chains, chainsTestnet } from "../../helpers/check";
import { Button, Modal, Box, CardMedia } from "@mui/material";
import button from '../../images/button.png';
import eth2ab from '../../images/eth2ab.png';

export const ConnectEthWallet = () => {
  const isMainnet = isMainnetUrl();
  const {
    ethAccount,
    setEthAccount,
    ethNetwork,
    networkId,
    setNetworkId,
    setNetworks,
    setEthNetwork,
    setEthBalance,
  } = useGeneralDataContext();

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const loadWeb3 = async (ethAccount) => {
    // Metamask
    if (window.ethereum) {
      window.web3 = new ethers.providers.Web3Provider(window.ethereum);
      console.log(ethAccount);
      const balance = await window.web3.getBalance(ethAccount);
      // Convert balance from wei to ether
      const etherBalance = ethers.utils.formatEther(balance);
      setEthBalance(Number(etherBalance).toFixed(2));

      window.ethereum.on("accountsChanged", async function () {
        await connectToMetaMask();
      });

      window.ethereum.on("chainChanged", async function () {
        await switchToSepolia();
        await connectToMetaMask();
      });
    } else {
      console.log('No metamask wallet detected');
    }
  };

  const connectToMetaMask = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      let chainId = await window.ethereum.request({ method: "eth_chainId" });
      chainId = parseInt(chainId, 16);
      setNetworkId(chainId);

      if (chainId !== 11155111) { // Sepolia chain ID
        await switchToSepolia();
      }

      console.log(accounts[0].toLowerCase());
      setEthAccount(accounts[0].toLowerCase());
      await loadWeb3(accounts[0].toLowerCase());
    } else {
      errorHandler("No metamask wallet detected");
    }
  };

  const switchToSepolia = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xaa36a7' }], // Sepolia chain ID in hexadecimal
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0xaa36a7', // Sepolia chain ID in hexadecimal
                chainName: 'Sepolia Testnet',
                rpcUrls: ['https://rpc.sepolia.org'],
                nativeCurrency: {
                  name: 'Sepolia Ether',
                  symbol: 'ETH',
                  decimals: 18,
                },
                blockExplorerUrls: ['https://sepolia.etherscan.io'],
              },
            ],
          });
        } catch (addError) {
          errorHandler("Failed to add Sepolia network");
        }
      } else {
        errorHandler("Failed to switch to Sepolia network");
      }
    }
  };

  const loadWeb32 = async () => {
    // Wallet Provider
    console.log(ethAccount, 'acc');
    if (walletProvider) {
      window.web3 = new ethers.providers.Web3Provider(walletProvider);
      console.log('Wallet provider loaded');

      const balance = await window.web3.getBalance(ethAccount);
      // Convert balance from wei to ether
      const etherBalance = ethers.utils.formatEther(balance);
      console.log(etherBalance);
      setEthBalance(Number(etherBalance).toFixed(2));
    } else {
      console.log('No wallet provider');
    }
  };

  const wcLoad = async () => {
    setNetworkId(chainId);
    setEthAccount(address.toLowerCase());
    await loadWeb32();
  };

  const connectToWalletConnect = async () => {
    open();
  };

  const disconnectETHWallet = async () => {
    try {
      setEthAccount("");
      if (isConnected) {
        disconnect();
      }
    } catch (error) {
      errorHandler("Error disconnecting wallet, please try again.");
    }
  };

  useEffect(() => {
    if (isConnected && chainId && ethNetwork && ethNetwork.name && ethAccount) {
      wcLoad();
    }
  }, [isConnected, chainId, address, ethNetwork]);

  useEffect(() => {
    if (isConnected && chainId && address) {
      wcLoad();
    }
  }, [isConnected, chainId, address]);

  useEffect(() => {
    if (networkId && ethNetwork && ethNetwork.name && ethAccount) {
      connectToMetaMask();
    }
  }, [ethNetwork]);

  useEffect(() => {
    if (isMainnet) {
      setEthNetwork({ ...chains[0], icon: `/icons/${chains[0].chainId}.svg` });
    } else {
      setEthNetwork({
        ...chainsTestnet[0],
        icon: `/icons/${chainsTestnet[0].chainId}.svg`,
      });
    }
  }, []);

  return (
    <>
      {ethAccount ? (
        <Button onClick={disconnectETHWallet} size='small' sx={{ backgroundImage: ` url(${button})`, textTransform:'none', backgroundSize: '100% 100%', height: '3.3rem !important', py: '0', backgroundRepeat: 'no-repeat', px: '25px', marginRight: '10px', fontSize: { xl: '0.75rem', lg: '12px' }, color: 'white' }}>
          <Box sx={{ bgcolor: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', height: '1.3rem', width: '1.3rem' }}>
            <CardMedia component='img' image={eth2ab} sx={{ height: '1rem', width: 'auto' }} />
          </Box>
          {showAddress(ethAccount)}
        </Button>
      ) : (
        <Button onClick={connectToMetaMask} size='small' sx={{ backgroundImage: ` url(${button})`, backgroundSize: '100% 100%', height: '3.3rem !important', py: '0', backgroundRepeat: 'no-repeat', px: '25px', marginRight: '10px', fontSize: { xl: '0.75rem', lg: '12px' }, color: 'white', textTransform:'none' }}>Connect ETH Wallet</Button>
      )}
    </>
  );
};
