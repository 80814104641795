import { Box, Button, Grid, Typography, colors } from '@mui/material'
import React from 'react'
import { Icon } from "@iconify/react";
import TableDetails from './TableDetails';
import button from '../../images/button.png'

const bg = { borderRadius: '12px', padding: '1rem', border: '1px solid rgba(214, 214, 214, 0.04)', background: 'rgba(214, 214, 214, 0.04)', backdropFilter: 'blur(12px)' }
const iconBg = { borderRadius: '12px', display: 'flex', width: '3rem', alignItems: 'center', padding: '5%', background: 'rgba(255, 255, 255, 0.08)' }
const pcss = { fontFamily: 'Roboto Mono', color: 'rgba(255, 255, 255, 0.60)', fontWeight: '200', fontSize: '0.9rem', marginTop: '5%' }
const h4css = { fontFamily: 'Inter', fontWeight: '600', fontSize: '1.5rem', marginTop: '3%' }

export default function DashboardDetails() {
    return (
        <>
            <Grid container gap={2}>
                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                        <Icon icon="solar:medal-ribbons-star-bold" style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >Marketcap</Typography>
                    <Typography variant='h4' sx={h4css} >$1,243,092</Typography>
                </Grid>
                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                    <Icon icon="mage:id-card-fill"  style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >TapBridge Total Volume</Typography>
                    <Typography variant='h4' sx={h4css} >$404,954,014.42</Typography>
                </Grid>
                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                    <Icon icon="fluent:replay-32-filled"  style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >Total $TBRG Transactions</Typography>
                    <Typography variant='h4' sx={h4css} >$5,525</Typography>
                </Grid>
                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                    <Icon icon="bxs:dollar-circle" style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >$TBRG Price</Typography>
                    <Typography variant='h4' sx={h4css} >$1,243,092</Typography>
                </Grid>



                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                    <Icon icon="solar:tag-bold"  style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >Number of Assets Supported</Typography>
                    <Typography variant='h4' sx={h4css} >31 Tokens</Typography>
                </Grid>
                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                    <Icon icon="solar:ticket-sale-bold"  style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >Total Value Bridged</Typography>
                    <Typography variant='h4' sx={h4css} >$23,045</Typography>
                </Grid>
                <Grid item md={2.85} sx={bg}>
                    <Box component='div' sx={iconBg}>
                        <Icon icon="solar:medal-ribbons-star-bold" style={{ fontSize: '1.3rem' }} />
                    </Box>
                    <Typography variantMapping='p' sx={pcss} >Confirmed Transactions</Typography>
                    <Typography variant='h4' sx={h4css} >54</Typography>
                </Grid>
                <Grid item md={2.85} sx={{...bg, display:'flex', alignItems:'flex-end'}}>
                <Button size='small' sx={{ backgroundImage: ` url(${button})`, backgroundSize: '100% 100%', height: '3.3rem !important', py: '0', backgroundRepeat: 'no-repeat', px: '25px', marginRight: '10px', fontSize: { xl: '0.75rem', lg: '12px' }, color: 'white' }}>
                Buy $TBRDG
        </Button>
                </Grid>

                <Grid item md={11.9} sx={{marginTop:'5%'}}>
                    <TableDetails />
                </Grid>

            </Grid>
        </>
    )
}
