import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CardMedia } from '@mui/material';
import { isMainnetUrl, isValidTaprootAddress, showAddress } from "../../helpers/check";
import { useGeneralDataContext } from "../../hooks/useGeneralDataContext";
import errorHandler from "../../helpers/errorHandler";
import transbutton from '../../images/transbutton.png'
import btcab from '../../images/btcab.png'
export const ConnectBtcWallet = () => {
  const isMainnet = isMainnetUrl();
  const unisat = window.unisat;

  const [connected, setConnected] = useState(false);
  const [network, setNetwork] = useState(isMainnet ? "livenet" : "testnet");
  const [accounts, setAccounts] = useState([]);
  const [publicKey, setPublicKey] = useState("");
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });
  const { btcAccount, setBtcAccount } = useGeneralDataContext();

  useEffect(() => {
    if (btcAccount && !isValidTaprootAddress(btcAccount) && connected) {
      errorHandler("Please use Taproot BTC address");
    }
  }, [btcAccount, connected]);

  const loadUnisat = async () => {
    if (window.unisat) {
      let unisat = window.unisat;

      unisat.on("accountsChanged", handleAccountsChanged);
      unisat.on("networkChanged", handleNetworkChanged);
    } else {
      errorHandler("No unisat wallet detected");
    }
  };

  const getBasicInfo = async () => {
    const unisat = window.unisat;
    const [address] = await unisat.getAccounts();
    setBtcAccount(address.toLowerCase());

    const publicKey = await unisat.getPublicKey();
    setPublicKey(publicKey);

    const balance = await unisat.getBalance();
    setBalance(balance);

    const network = await unisat.getNetwork();
    setNetwork(network);
  };

  useEffect(() => {
    const switchNetwork = async () => {
      const network = await window.unisat?.getNetwork();
      // if (isMainnet) {
        if (network && network !== "livenet") {
          await unisat.switchNetwork("livenet");
        }
      // } else {
      //   if (network && network !== "testnet") {
      //     await unisat.switchNetwork("testnet");
      //   }
      // }
    };
    if (connected) {
      switchNetwork();
    }
  }, [network]);

  const selfRef = useRef({
    accounts: [],
  });
  const self = selfRef.current;
  const handleAccountsChanged = (_accounts) => {
    if (self.accounts[0] === _accounts[0]) {
      setBtcAccount(_accounts[0].toLowerCase());
      // prevent from triggering twice
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);

      setBtcAccount(_accounts[0].toLowerCase());

      loadUnisat();
      getBasicInfo();
    } else {
      setConnected(false);
    }
  };

  const handleNetworkChanged = (network) => {
    setNetwork(network);
    getBasicInfo();
  };

  const disconnectBTCWallet = async () => {
    setBtcAccount("");
  };

  const connectWallet = async () => {
    try {
      const unisat = window.unisat;
      const result = await unisat.requestAccounts();
      handleAccountsChanged(result);
    } catch (error) {
      errorHandler(error.message)
    }
  }

  return (
    <>
      {btcAccount ? (
        <Button size='small' onClick={disconnectBTCWallet} sx={{ backgroundImage: ` url(${transbutton})`, textTransform:'none', backgroundSize: '100% 100%', backdropFilter: 'blur(5px)', height: '3.3rem !important', py: '0', backgroundRepeat: 'no-repeat', px: '25px', fontSize: { xl: '0.75rem', lg: '12px' }, color: 'white' }}>
          <Box sx={{ background: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) !important', backgroundClip: 'text !important', display:'flex', WebkitTextFillColor: 'transparent' }}>
              <CardMedia component='img' image={btcab} sx={{ height: '1.3rem', width: 'auto', marginRight:'10px' }} />
            {showAddress(btcAccount)}
          </Box></Button>
      ) : (
        <Button size='small' onClick={connectWallet} sx={{ backgroundImage: ` url(${transbutton})`, backgroundSize: '100% 100%', backdropFilter: 'blur(5px)', height: '3.3rem !important', py: '0', backgroundRepeat: 'no-repeat', px: '25px', fontSize: { xl: '0.75rem', lg: '12px' }, color: 'white' }}>
          <Box sx={{ background: 'linear-gradient(90deg, #F5872A 0.01%, #E84C63 99.99%) !important', backgroundClip: 'text !important', WebkitTextFillColor: 'transparent', textTransform:'none' }}>
            Connect BTC Wallet
          </Box></Button>
      )}
    </>
  );
};
