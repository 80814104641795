import React from 'react'
import DrawerAppBar from '../components/drawer/NavBar'
import Landing from '../components/Landing'

export default function LandingPage() {
  return (
    <>
        <DrawerAppBar>
        <Landing/>
      </DrawerAppBar>
    </>
  )
}
